import { DeferredPromise } from '../../utils/DeferredPromise';
import makeLogger from '../../utils/makeLogger';
import { IGNORE_SENTRY_PREFIX } from './errors';
import { calculateMSE, computeHeaderImageColorData, doesTextContentHaveImageNearBeginning } from './imageUtils';
import { MobileContentFrameWindow } from './types';

const logger = makeLogger(__filename);
declare let window: MobileContentFrameWindow;

const secondaryHeaderImagePromise = new DeferredPromise<boolean>();
const faviconImagePromise = new DeferredPromise<boolean>();

export const secondaryHeaderErrorMessage = `${IGNORE_SENTRY_PREFIX} header image didnt load`;

const imagesThatFailedToLoad: HTMLImageElement[] = [];

const reloadImage = (img: HTMLImageElement) => {
  const imageElement = img;
  const imageSrc = imageElement.src;
  imageElement.src = '';
  imageElement.src = imageSrc;
};

const addCrossOrigin = (image: HTMLImageElement) => {
  image.crossOrigin = 'anonymous';
  image.referrerPolicy = 'no-referrer';
};

window.reloadImagesThatFailedToLoad = () => {
  imagesThatFailedToLoad.forEach((image) => {
    reloadImage(image);
  });
  // Empty array after retrying to eventually collect images that errored again
  imagesThatFailedToLoad.length = 0;
};

window.onFaviconLoaded = () => {
  if (faviconImagePromise.status === 'pending') {
    faviconImagePromise.resolve(true);
  }
};

let faviconTries = 0;
const faviconErrorMessage = `${IGNORE_SENTRY_PREFIX} favicon didnt load`;

window.onFaviconError = () => {
  const favicon = document.querySelector('.document-header-domain-favicon') as HTMLImageElement;

  if (!favicon) {
    faviconImagePromise.resolve(false);
    return;
  }

  addCrossOrigin(favicon as HTMLImageElement);

  faviconTries += 1;

  if (faviconTries > 1) {
    favicon.style.opacity = '0';
    faviconImagePromise.reject(new Error(faviconErrorMessage));
  }
};

window.onSecondaryHeaderImageLoaded = () => {
  if (secondaryHeaderImagePromise.status === 'pending') {
    secondaryHeaderImagePromise.resolve(true);
  }
  const secondaryHeaderImageContainer = document.querySelector('.header-secondary-image-container');
  secondaryHeaderImageContainer?.classList.remove('transparent');
};
let secondaryHeaderTries = 0;
window.onSecondaryHeaderImageError = () => {
  const secondaryHeaderImage = document.querySelector('#secondary-header-image') as HTMLImageElement;

  addCrossOrigin(secondaryHeaderImage);

  secondaryHeaderTries += 1;
  if (secondaryHeaderTries > 1) {
    secondaryHeaderImagePromise.reject(new Error(secondaryHeaderErrorMessage));
  }
};

window.onHeaderImageLoaded = () => {
  const HeaderImageContainer = document.querySelector('#header-image-container');
  HeaderImageContainer?.classList.remove('transparent');
  computeHeaderImageColorData();
};

window.onHeaderImageError = () => {
  const headerImage = document.querySelector('#header-image') as HTMLImageElement;

  addCrossOrigin(headerImage);
};

export const updateAllSVGsOnContentLoad = () => {
  const svgs = document.querySelectorAll('svg');
  // For any SVGs that have no content (common in epubs), don't display them
  for (const svg of svgs) {
    if (svg.children.length === 0) {
      svg.classList.add('display-none');
    }
  }
};

export default () => {
  const contentRoot = document.querySelector('#document-text-content');
  if (!contentRoot) {
    throw new Error('No #document-text-content found');
  }
  const images = contentRoot.querySelectorAll('img');

  const imgOnError: OnErrorEventHandler = (event: string | Event) => {
    if (typeof event === 'string') {
      return;
    }
    const imageElement = event?.target as HTMLImageElement;
    imagesThatFailedToLoad.push(imageElement);
  };

  for (const image of images) {
    image.onerror = imgOnError;
  }

  const awaitHeaderAndFaviconImages = async () => {
    return Promise.all([secondaryHeaderImagePromise, faviconImagePromise]);
  };

  const getImageDataFromCanvas = (
    image: HTMLImageElement,
    canvasId: string,
    height: number,
    width: number,
  ) => {
    const canvas = document.querySelector(canvasId) as HTMLCanvasElement;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    ctx.drawImage(image, 0, 0, height, width);
    return ctx.getImageData(0, 0, height, width);
  };

  const renderSecondaryHeaderIfNecessary = async () => {
    const secondaryHeaderImageContainer = document.querySelector('.header-secondary-image-container');
    const faviconImage = document.querySelector('#favicon-image') as HTMLImageElement;
    const secondaryHeaderImage = document.querySelector('#secondary-header-image') as HTMLImageElement;

    if (!secondaryHeaderImageContainer || !secondaryHeaderImage) {
      return;
    }
    if (secondaryHeaderImage.src.includes('readwise')) {
      secondaryHeaderImageContainer.classList.add('hidden-image');
      return;
    }
    if (faviconImage && faviconImage.src === secondaryHeaderImage.src) {
      secondaryHeaderImageContainer.classList.add('hidden-image');
      return;
    }
    if (doesTextContentHaveImageNearBeginning()) {
      secondaryHeaderImageContainer.classList.add('hidden-image');
      return;
    }
    secondaryHeaderImageContainer.classList.remove('hidden-image');
    try {
      const [, faviconImageLoaded] = await awaitHeaderAndFaviconImages();

      if (
        secondaryHeaderImage.height <= 64 && secondaryHeaderImage.width <= 64 ||
        secondaryHeaderImage.naturalHeight <= 64 && secondaryHeaderImage.naturalWidth <= 64
      ) {
        secondaryHeaderImageContainer.classList.add('hidden-image');
        return;
      }
      // Finally, is this image too similar to its favicon?
      if (faviconImageLoaded) {
        const faviconData = getImageDataFromCanvas(faviconImage, '#imageCompareCanvas1', 100, 100);
        const headerData = getImageDataFromCanvas(
          secondaryHeaderImage,
          '#imageCompareCanvas2',
          100,
          100,
        );

        if (faviconData?.data && headerData?.data) {
          const mse = calculateMSE(faviconData.data, headerData.data);
          if (mse < 3000) {
            secondaryHeaderImageContainer.classList.add('hidden-image');
            return;
          }
        }
      }
      secondaryHeaderImageContainer.classList.remove('hidden-image');
    } catch (e) {
      logger.debug('Images most likely didnt load');
    }
  };

  renderSecondaryHeaderIfNecessary();
};
