export const getOriginalEmailStyles = (deviceWidth: number) => `
<style>
  table, tr, tbody {
    width: 100% !important;
  }

  table, tr, td, tbody, pre {
    max-width: 100% !important;
  }

  .document-container :is(div, table, tr, td, tbody, a, hr, pre) {
    max-width: ${deviceWidth - 20}px !important;
  }

  pre {
    display: block;
    overflow-x: scroll !important;
  }

  .document-content--original-email {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: content-box;
  }
</style>
`;
