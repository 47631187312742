import sumBy from 'lodash/sumBy';

import type { WebviewDocumentChunk } from '../types/chunkedDocuments';

export function getWordsScrolled(scrollDepth: number, totalWordsInDocument?: number) {
  return totalWordsInDocument ? totalWordsInDocument * scrollDepth : 0;
}

export function getWordsScrolledDelta(wordsScrolled: number, startScrollValue: number) {
  return wordsScrolled - startScrollValue;
}

export function getIsScrolledToBottom(scrollDepth: number) {
  return scrollDepth === 1;
}

type ProgressParams = {
  contentElement: HTMLElement | undefined;
  viewportHeight: number;
  centeredElement?: HTMLElement; // Optional override for content top position
  bottomOffset?: number; // Optional offset in pixels to account for bottom element differences in mobile and web
  startPercentageInViewport?: number; // Optional percentage to start the progress calculation from
};

/**
 * Calculates how far the content has been scrolled as a value between 0 and 1.
 *
 * This function accepts either a top value or falls back to the top of the content element.
 */
function calculateContentProgress({
  contentElement,
  viewportHeight,
  centeredElement,
  bottomOffset = 0,
  startPercentageInViewport = 0.5,
}: ProgressParams): number {
  if (!contentElement) {
    return 0;
  }

  const contentRect = contentElement.getBoundingClientRect();
  const viewPortOffset = viewportHeight * startPercentageInViewport;

   // Calculate how much content has been scrolled above the threshold
  //  We use the headerTop to start scrolling progress immediately when the user
  //  scrolls from the beginning of the document.
  const contentTop = centeredElement ? contentRect.top - centeredElement.getBoundingClientRect().top : contentRect.top;

  const totalAboveViewport = Math.max(0, -contentTop + viewPortOffset);

  // This accounts for the bottom elements that take up space in the viewport
  // They are defined as absolute pixels and thus we cannot work with relative viewportHeight here.
  const totalScrollableHeight = contentRect.height - bottomOffset + viewPortOffset;

  // The px values cancel out, so we receive a device independent value
  const progress = totalAboveViewport / totalScrollableHeight;

  return Math.min(1, Math.max(0, progress));
}

export function getChunkScrollDepthInWeb(currentChunkElement: HTMLElement | undefined, serializedPositionElement?: HTMLElement) {
  // We use the window and bottom element height to calculate an offset
  const bottomOffset = window.innerHeight + 205;

  return calculateContentProgress({
    contentElement: currentChunkElement,
    viewportHeight: window.innerHeight,
    bottomOffset,
    startPercentageInViewport: 0.3,
    centeredElement: serializedPositionElement,
  });
}

export function getChunkScrollDepthInMobile(currentChunkElement: HTMLElement | undefined, serializedPositionElement?: HTMLElement) {
  const bottomOffset = window.screen.height - 520;

  return calculateContentProgress({
    contentElement: currentChunkElement,
    // On mobile innerHeight can change while the content is loading
    viewportHeight: window.screen.height,
    bottomOffset,
    startPercentageInViewport: 0.2,
    centeredElement: serializedPositionElement,
  });
}

function getTotalWordsInDocument(documentChunks: WebviewDocumentChunk[]) {
  return sumBy(documentChunks, (chunk) => chunk.word_count ?? 0);
}

function getWordsUntilCurrentChunk(documentChunks: WebviewDocumentChunk[], currentChunkIndex: number) {
  return sumBy(documentChunks.slice(0, currentChunkIndex), (chunk) => chunk.word_count ?? 0);
}

type DocumentReadingProgress = {
  wordsScrolled: number;
  relativeProgress: number;
};

export function calculateDocumentReadingProgress(
  documentChunks: WebviewDocumentChunk[],
  currentChunkIndex: number,
  relativeProgressInChunk: number,
): DocumentReadingProgress {
  if (documentChunks.length === 0) {
    return { wordsScrolled: 0, relativeProgress: 0 };
  }

  if (documentChunks.length === 1) {
    const totalWords = documentChunks[0].word_count ?? 0;
    return {
      wordsScrolled: totalWords * relativeProgressInChunk,
      relativeProgress: relativeProgressInChunk,
    };
  }

  const totalWordsInDocument = getTotalWordsInDocument(documentChunks);
  if (totalWordsInDocument === 0) {
    return { wordsScrolled: 0, relativeProgress: 0 };
  }

  const wordsUntilCurrentChunk = getWordsUntilCurrentChunk(documentChunks, currentChunkIndex);
  const currentChunk = documentChunks[currentChunkIndex];
  const relativeWordsInCurrentChunk = relativeProgressInChunk * (currentChunk?.word_count ?? 0);
  const totalWordsScrolled = wordsUntilCurrentChunk + relativeWordsInCurrentChunk;

  return {
    wordsScrolled: totalWordsScrolled,
    relativeProgress: Number(totalWordsScrolled / totalWordsInDocument),
  };
}
