import { EventEmitter2 } from 'eventemitter2';

import { ContentFrameEventMessageArgumentMap } from '../types/events';

interface TypedEventEmitter extends EventEmitter2 {
  // Custom typed overloads
  emit<K extends keyof ContentFrameEventMessageArgumentMap>(
    event: K,
    ...args: ContentFrameEventMessageArgumentMap[K] extends undefined
      ? []
      : [ContentFrameEventMessageArgumentMap[K]]
  ): boolean;

  on<K extends keyof ContentFrameEventMessageArgumentMap>(
    event: K,
    listener: (
      ...args: ContentFrameEventMessageArgumentMap[K] extends undefined
        ? []
        : [ContentFrameEventMessageArgumentMap[K]]
    ) => void,
  ): this;

  // Native EventEmitter2 methods are inherited automatically
}

const typedEventEmitter = new EventEmitter2({
  maxListeners: 999,
}) as TypedEventEmitter;

export default typedEventEmitter;
