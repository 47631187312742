export enum PromptScope1 {
  Document = 'DOCUMENT',
}

export enum PromptModel {
  // TODO: These lint rules are excessive
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums, @typescript-eslint/naming-convention
  GPT_35_TURBO = 'GPT_35_TURBO',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums, @typescript-eslint/naming-convention
  GPT_4 = 'GPT_4',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums, @typescript-eslint/naming-convention
  GPT_4_TURBO = 'GPT_4_TURBO',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums, @typescript-eslint/naming-convention
  GPT_4o = 'GPT_4o',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums, @typescript-eslint/naming-convention
  GPT_4o_MINI = 'GPT_4o_MINI',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums, @typescript-eslint/naming-convention
  O1 = 'O1',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums, @typescript-eslint/naming-convention
  O1_MINI = 'O1_MINI',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums, @typescript-eslint/naming-convention
  O3_MINI = 'O3_MINI',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums, @typescript-eslint/naming-convention
}

export const DefaultModel = PromptModel.GPT_4o_MINI;

export enum PromptsVersion {
  V1 = '1',
  V2 = '2',
}

export enum PromptScopeType {
  Automatic = 'AUTOMATIC',
  Document = 'DOCUMENT',
  DocumentSpecial = 'DOCUMENT_SPECIAL',
  Passage = 'PASSAGE',
  Word = 'WORD',
}

export enum PromptType {
  // word and phrase level prompts
  Define = 'DEFINE',
  EncyclopediaLookup = 'LOOKUP',
  TermLookup = 'TERM_LOOKUP',
  // passage level prompts
  Flashcard = 'FLASHCARD',
  PermanentNote = 'PERMANENT_NOTE',
  Simplify = 'SIMPLIFY',
  // document level prompts
  GenerateQuestions = 'GENERATE_QUESTIONS',
  WriteReview = 'WRITE_REVIEW',
  // generic
  Ask = 'ASK',
  HighlightDocument = 'HIGHLIGHT_DOCUMENT',
  SummarizeDocument = 'SUMMARIZE_DOCUMENT',
  TagDocument = 'TAG_DOCUMENT',
  Translate = 'TRANSLATE',
  // custom
  Custom = 'CUSTOM',
  Generic = 'GENERIC',
}

export enum PromptReturnType {
  Text = 'TEXT',
  List = 'LIST',
}

export enum PromptResponseAction {
  AppendToNote = 'APPEND_TO_NOTE',
  CreateTags = 'CREATE_TAGS',
  CreateHighlights = 'CREATE_HIGHLIGHTS',
  UpdateSummary = 'UPDATE_SUMMARY',
}
