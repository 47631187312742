// eslint-disable-next-line import/no-unresolved
import 'virtual:fonts.css';
import 'shared/foreground/utils/scrollingPolyfill';

import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import clearLocalRxdbData from 'shared/database/clearLocalRxdbData.platform';
import onLoggedOut from 'shared/foreground/onLoggedOut.platform';
import background from 'shared/foreground/portalGates/toBackground';
import setUpStateTree from 'shared/foreground/setUpStateTree';
import { init as initUserEvents, onAppFocusChange } from 'shared/foreground/userEvents';
import type { LenientWindow } from 'shared/types/LenientWindow';
import { isDesktopApp } from 'shared/utils/environment';
import exceptionHandler from 'shared/utils/exceptionHandler.platform';

import { handleDesktopAuthCallback } from './utils/auth.desktop';
import { handleInitialDeepLink } from './utils/deeplink.desktop';
import { reactLazy } from './utils/dynamicImport';

declare let window: LenientWindow;

const App = reactLazy(() => import('./App'));

window.clearRxDbStorageAndReload = async () => {
  await clearLocalRxdbData();
  window.location.reload();
};

try {
  exceptionHandler.init(
    'https://3c7b2ce17cea43b4bd430716e7a51757@o374023.ingest.sentry.io/5795514',
  );
} catch (e) {
  // Ignore
}

if (isDesktopApp) {
  // We need wait until we store the Desktop session ID or else we get stuck in an infinite loop and crash.
  handleDesktopAuthCallback()
    .then(handleInitialDeepLink).then(setupApp)
    // eslint-disable-next-line no-console
    .catch(console.error);
} else {
  setupApp();
}

function setupApp() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  background.init({ onLoggedOut });
  initUserEvents();
  const onFocusChange = async (event: FocusEvent) => onAppFocusChange(event.type === 'focus');
  window.addEventListener('blur', onFocusChange);
  window.addEventListener('focus', onFocusChange);
  const container = document.getElementById('readwise-reader-root');
  if (!container) {
    throw new Error('readwise-reader-root not found');
  }
  const root = createRoot(container);
  root.render(
    <Suspense fallback={null}>
      <App />
    </Suspense>,
  );

  setUpStateTree();
}
