import { getCurrent } from '@tauri-apps/plugin-deep-link';
import { getDesktopAppInfo } from 'shared/utils/appInfo.desktop';

const deeplinkUrlScheme = 'wiseread://';

export async function transformDeepLinkUrlToLocal(url: string) {
  try {
    const desktopAppInfo = await getDesktopAppInfo();
    if (!desktopAppInfo) {
      return null;
    }

    if (desktopAppInfo.platform === 'windows') {
      return url.replace(deeplinkUrlScheme, 'http://tauri.localhost/');
    } else {
      // macOS
      return url.replace(deeplinkUrlScheme, 'tauri://localhost/');
    }
  } catch (error) {
    return null;
  }
}

export async function handleInitialDeepLink () {
  try {
    // Store we already handled the deep link so we don't end up in an infinite loop.
    if (sessionStorage.getItem('deeplink-handled')) {
      return;
    }
    const deepLinkUrls = await getCurrent();
    if (deepLinkUrls && deepLinkUrls.length > 0) {
      const desktopDeepLink = await transformDeepLinkUrlToLocal(deepLinkUrls[0]);
      if (desktopDeepLink) {
        sessionStorage.setItem('deeplink-handled', 'true');
        // Navigate to the desktop deep link.
        location.href = desktopDeepLink;
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error handling deep link', error);
  }
}
