import type { DocumentContent, RSSSuggestion } from '../../types';
import type { DocumentChunk } from '../../types/chunkedDocuments';
import Store from './Store'; // eslint-disable-line import/no-cycle

export default {
  documentContent: new Store<DocumentContent, 'id'>({
    endpointPrefix: 'documents/content',
    name: 'documentContent',
  }),
  documentContentChunks: new Store<DocumentChunk, 'id'>({
    endpointPrefix: 'documents/chunks',
    name: 'documentContentChunks',
  }),
  knownRssFeeds: new Store<RSSSuggestion, 'domain'>({
    couldServerReturnFewerItemsThanRequested: true,
    endpointPrefix: 'known_rss_feeds',
    name: 'knownRssFeeds',
    identifierProperty: 'domain',
  }),
};
