// WARNING: This function closely maps to a similar function in views.py on the backend used for GPT.
import { findAllElementsFromTagList } from './findAllElementsFromTagList';

const focusableTags = new Set([
  'P',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
  'LI',
  'BLOCKQUOTE',
  'A',
  'SPAN',
  'FIGURE',
  'IMG',
]);
const checkIfElementHasAnyChildren = (child: ChildNode) => {
  return child.childNodes.length > 0;
};

export function populateFocusableElements(element: ChildNode, result: ChildNode[]): void {
  findAllElementsFromTagList(element, result, focusableTags);
}

export function populateValidElementsToScrollTo(element: ChildNode, result: ChildNode[]): void {
  findAllElementsFromTagList(element, result, focusableTags, checkIfElementHasAnyChildren);
}
