import * as environment from './environment';

export default function getServerBaseUrl(isDevArgument?: boolean): string {
  const isDev = typeof isDevArgument === 'undefined' ? environment.isDevOrTest : isDevArgument;
  if (isDev) {
    if (!process.env.VITE_SERVER_BASE_URL) {
      return 'https://readwise.io';
      throw new Error('VITE_SERVER_BASE_URL missing');
    }
    return (process.env.VITE_SERVER_BASE_URL as string).replace(/\/+$/g, '');
  }
  if (
    window.location.hostname.startsWith('read--staging') &&
    window.location.hostname.endsWith('.readwise.io')
  ) {
    return 'https://sweetsweetstaging.readwise.io';
  }
  return 'https://readwise.io';
}
