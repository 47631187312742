import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';
import omit from 'lodash/omit';
import remove from 'lodash/remove';

import batchifyDatabaseUpdate from '../../../database/batchify';
import {
  type ClientState,
  type Font,
  type FullZustandState,
  type TextDirection,
  type TshirtSize,
  type UserEvent,
  Category, ReadingProgressViewVariant,
} from '../../../types';
import type { GlobalTag } from '../../../types/tags';
import { notEmpty } from '../../../typeValidators';
import { cleanAndValidateTagName } from '../../../utils/cleanAndValidateTagName';
import { isMobile } from '../../../utils/environment';
import makeLogger from '../../../utils/makeLogger';
import database from '../../database';
import { WEB_FONTS_SANS_SERIF, WEB_FONTS_SERIF } from '../../fonts';
import {
  DefaultModel,
  OverriddenPrompts,
  Prompt,
  PromptResponseAction,
  PromptReturnType,
  Prompts,
  PromptScopeType,
  PromptsVersion,
  PromptType,
} from '../../ghostreader';
import { CreatedPrompts } from '../../ghostreader/types';
import { migrateOverriddenPromptsFormat, migratePromptsFormat } from '../../ghostreader/utils';
// eslint-disable-next-line import/no-cycle
import { getDefaultClientState, globalState, isStaffProfile, updateState } from '../../models';
import { createToast } from '../../toasts.platform';
import { StateUpdateOptionsWithoutEventName } from '../../types';
import fixBadDocumentLocationsValue from '../../utils/fixBadDocumentLocationsValue';

const logger = makeLogger(__filename);

/*
  Global tags are now stored in RxDB but they were once stored in client state under `allTags`. If that exists,
  we move the data over to the RxDB collection.
*/
export async function initClientData(
  clientData:
    | null
    | (FullZustandState['client'] & {
        allTags?: {
          [key: string]: {
            count: number;
            created: number;
            docsCount?: number;
            highlightsCount?: number;
            name: string;
          };
        };
      }),
): Promise<void> {
  const currentGlobalState = globalState.getState();

  const clientState = mergeWith(
    {},
    getDefaultClientState({
      documentLocations: fixBadDocumentLocationsValue(
        currentGlobalState.persistent.settings.documentLocations,
      ),
    }),
    clientData,
    (_, userStateValue) => {
      // We use array values for some client state values, we don't want to merge them with defaults
      if (isArray(userStateValue)) {
        return userStateValue;
      }
    },
  );

  const promises: Promise<unknown>[] = [
    updateState(
      (state: FullZustandState) => {
        state.client = omit(clientState, ['allTags']) as ClientState;
        state.clientStateLoaded = true;
      },
      { userInteraction: null, eventName: 'client-settings-updated', isUndoable: false },
    ),
  ];

  if (clientData?.allTags) {
    const globalTags: GlobalTag[] = Object.entries(clientData.allTags)
      .map(([id, allTagsTag]) => {
        const { cleanTagName, validationError } = cleanAndValidateTagName(allTagsTag.name);
        if (validationError) {
          logger.warn('Invalid tag name', { validationError });
          return;
        }
        return {
          firstClassDocumentsCount: allTagsTag.docsCount ?? 0,
          highlightsCount: allTagsTag.highlightsCount ?? 0,
          id,
          lastAssignedAt: allTagsTag.created,
          name: cleanTagName,
          totalCount: allTagsTag.count ?? 0,
        };
      })
      .filter(notEmpty);
    promises.push(
      batchifyDatabaseUpdate({
        args: [globalTags, {}],
        collectionName: 'global_tags',
        func: database.collections.global_tags.bulkUpsert,
      }),
    );
  }

  await Promise.all(promises);
}

export const setFontSize = async (
  val: TshirtSize,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isMobile) {
        state.client.readerSettings.mobile.fontSize = val;
      } else {
        state.client.readerSettings.desktop.fontSize = val;
      }
    },
    { ...options, eventName: 'font-size-updated', isUndoable: false },
  );
};

export const setLineSpacing = async (
  val: TshirtSize,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isMobile) {
        state.client.readerSettings.mobile.lineHeight = val;
      } else {
        state.client.readerSettings.desktop.lineHeight = val;
      }
    },
    { ...options, eventName: 'line-spacing-updated', isUndoable: false },
  );
};

export const toggleShouldJustifyText = async (
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isMobile) {
        state.client.readerSettings.mobile.justifyText = !state.client.readerSettings.mobile.justifyText;
      } else {
        state.client.readerSettings.desktop.justifyText =
          !state.client.readerSettings.desktop.justifyText;
      }
    },
    { ...options, eventName: 'justify-text-updated', isUndoable: false },
  );
};

export const setReaderHorizontalMargin = async (
  val: TshirtSize,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isMobile) {
        state.client.readerSettings.mobile.pageWidth = val;
      } else {
        state.client.readerSettings.desktop.lineLength = val;
      }
    },
    { ...options, eventName: 'horizontal-margin-updated', isUndoable: false },
  );
};

export const setFont = async (
  font: Font,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isMobile) {
        state.client.readerSettings.mobile.font = font;
      } else {
        state.client.readerSettings.desktop.font = font;
      }
    },
    { ...options, eventName: 'mobile-font-updated', isUndoable: false },
  );
};

function getNextFont(currentFont: Font): Font {
  const keys = [...WEB_FONTS_SERIF, ...WEB_FONTS_SANS_SERIF];

  const currentIndex = keys.findIndex((key) => {
    return key === currentFont;
  });

  const firstFont = keys[0];
  return (keys[currentIndex + 1] || firstFont) as Font;
}

export const cycleTypeface = async (options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  const currentFont = globalState.getState().client.readerSettings.desktop.font;
  const nextFont = getNextFont(currentFont);
  setFont(nextFont, { userInteraction: 'unknown' });
};

export const setTextDirection = async (direction: TextDirection) => {
  const updateResult = await updateState(
    (state) => {
      if (isMobile) {
        state.client.readerSettings.mobile.direction = direction;
      } else {
        state.client.readerSettings.desktop.direction = direction;
      }
    },
    { eventName: 'text-direction-changed', userInteraction: null, isUndoable: false },
  );

  createToast({
    content: `Text direction changed to ${direction.toLocaleUpperCase()}`,
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const setPaginationAnimationsDisabled = async (disabled: boolean) => {
  if (!isMobile) {
    return;
  }
  await updateState(
    (state) => {
      state.client.readerSettings.mobile.arePaginationAnimationsDisabled = disabled;
    },
    { eventName: 'mobile-pagination-animations-toggled', userInteraction: null, isUndoable: false },
  );
};
// TODO: Re-enable if we want to try this again
// export const setPaginationHapticsOnScrollEnabled = async (enabled: boolean) => {
//   if (!isMobile) {
//     return;
//   }
//   await updateState((state) => {
//     state.client.readerSettings.mobile.arePaginationHapticsOnScrollEnabled = enabled;
//   }, { eventName: 'mobile-pagination-haptics-on-scroll-toggled', userInteraction: null, isUndoable: false });
// };
export const setPaginationDefaultForCategories = async (
  categories: Category[],
  defaultEnabled: boolean,
) => {
  if (!isMobile) {
    return;
  }
  await updateState(
    (state) => {
      const arrCopy = Array.from(state.client.readerSettings.mobile.paginationOnByDefaultList);
      for (const category of categories) {
        remove(arrCopy, (v) => v === category);
        if (defaultEnabled) {
          arrCopy.push(category);
        }
      }
      state.client.readerSettings.mobile.paginationOnByDefaultList = arrCopy;
    },
    {
      eventName: 'mobile-default-pagination-for-category-toggled',
      userInteraction: null,
      isUndoable: false,
    },
  );
};

export const toggleShouldInvertPDFColors = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.client.shouldInvertPDFColors = !state.client.shouldInvertPDFColors;
    },
    { userInteraction: 'unknown', eventName: 'toggle-should-invert-pdf-colors' },
  );
};

export const toggleMobileShouldUseVolumeButtonsToAdvancePage = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.client.mobileShouldUseVolumeButtonsToScrollPages =
        !state.client.mobileShouldUseVolumeButtonsToScrollPages;
    },
    { userInteraction: 'tap', eventName: 'toggle-mobile-should-use-volume-buttons-to-advance-page' },
  );
};
export const toggleMobileAreAnimationsDisabled = async (override?: boolean): Promise<void> => {
  await updateState(
    (state) => {
      if (override !== undefined) {
        state.client.mobileAreAnimationsDisabled = override;
      } else {
        state.client.mobileAreAnimationsDisabled = !state.client.mobileAreAnimationsDisabled;
      }
    },
    { userInteraction: 'tap', eventName: 'toggle-mobile-are-animations-disabled' },
  );
};

export const toggleMobileTapToShowMenusDisabled = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.client.mobileTapToShowMenusDisabled = !state.client.mobileTapToShowMenusDisabled;
    },
    { userInteraction: 'unknown', eventName: 'mobile-toggle-tap-to-show-menus', isUndoable: false },
  );
};

export const toggleMobileReadingProgressButtonDisabled = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.client.mobileReadingProgressButtonDisabled =
        !state.client.mobileReadingProgressButtonDisabled;
    },
    {
      userInteraction: 'unknown',
      eventName: 'mobile-toggle-reading-button-disabled',
      isUndoable: false,
    },
  );
};

export const toggleIsGhostreaderEnabled = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.client.isGhostreaderEnabled = !state.client.isGhostreaderEnabled;
    },
    { userInteraction: 'tap', eventName: 'toggle-enable-ghostreader' },
  );
};

export const toggleBookwiseWipFeaturesEnabled = async (): Promise<void> => {
  await updateState(
    (state) => {
      if (!state.client.bookwise) {
        state.client.bookwise = {};
      }

      let currentValue = state.client.bookwise.areWipFeaturesEnabled;

      if (currentValue === undefined) {
        currentValue = isStaffProfile(state);
      }

      const newValue = !currentValue;
      state.client.bookwise.areWipFeaturesEnabled = newValue;

      if (newValue === false) {
        state.client.isGhostreaderEnabled = false;
      }
    },
    { userInteraction: 'tap', eventName: 'toggle-bookwise-wip-features-enabled' },
  );
};

export const toggleHighContrast = async (override?: boolean): Promise<void> => {
  await updateState(
    (state) => {
      if (override !== undefined) {
        state.client.isHighContrastMode = override;
      } else {
        state.client.isHighContrastMode = !state.client.isHighContrastMode;
      }
    },
    { userInteraction: 'tap', eventName: 'toggle-high-contrast-mode' },
  );
};

export const toggleDailyReview = async (override?: boolean): Promise<void> => {
  await updateState(
    (state) => {
      const profile = state.client.profile;
      if (!profile || !profile.is_staff) {
        state.client.showDailyReview = false;
        return;
      }
      if (override !== undefined) {
        state.client.showDailyReview = override;
      } else {
        state.client.showDailyReview = !state.client.showDailyReview;
      }
    },
    { userInteraction: 'tap', eventName: 'toggle-daily-review' },
  );
};

export const updatePrompt = async (scope: PromptScopeType, prompt: Prompt) => {
  await updateState(
    (state) => {
      state.persistent.settings.overriddenPrompts2 ??= {} as OverriddenPrompts;
      state.persistent.settings.overriddenPromptsVersion = PromptsVersion.V2;
      state.persistent.settings.overriddenPrompts2[scope] ??= {};
      state.persistent.settings.overriddenPrompts2[scope][prompt.type] = prompt;
    },
    { userInteraction: 'click', eventName: 'update-prompt' },
  );
};

export const updateCreatedPrompt = async (scope: PromptScopeType, prompt: Prompt) => {
  await updateState(
    (state) => {
      state.persistent.settings.createdPrompts ??= {} as CreatedPrompts;
      state.persistent.settings.createdPrompts[scope] ??= [];
      const index = state.persistent.settings.createdPrompts[scope].findIndex(
        (p) => p.position === prompt.position,
      );
      state.persistent.settings.createdPrompts[scope][index] = prompt;
    },
    { userInteraction: 'click', eventName: 'update-created-prompt' },
  );
};

export const createPrompt = async (scope: PromptScopeType) => {
  const prompt = {
    model: DefaultModel,
    system: 'Your are a helpful reading assistant',
    template:
      'I just came across the word or phrase "{{ selection }}" as used in the following sentence: "{{ selection.sentence }}"',
    title: 'New custom prompt',
    type: PromptType.Generic,
    position: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
    returnType: PromptReturnType.Text,
    responseAction: PromptResponseAction.AppendToNote,
    isEnabled: true,
  };

  await updateState(
    (state) => {
      state.persistent.settings.createdPrompts ??= {} as CreatedPrompts;
      state.persistent.settings.createdPrompts[scope] ??= [];
      state.persistent.settings.createdPrompts[scope].push(prompt);
    },
    { userInteraction: 'click', eventName: 'create-prompt' },
  );

  return prompt;
};

export const toggleIsPromptEnabled = async (promptScopeType: PromptScopeType, prompt: Prompt) => {
  await updateState(
    (state) => {
      const isEnabled = !(prompt.isEnabled !== false);

      if (promptScopeType === PromptScopeType.Automatic) {
        state.persistent.settings.openai ??= {};
        switch (prompt.type) {
          case PromptType.SummarizeDocument:
            state.persistent.settings.openai.isAutoSummarizeEnabled = isEnabled;
            break;
          case PromptType.TagDocument:
            state.persistent.settings.openai.isAutoTaggingEnabled = isEnabled;
            break;
        }
      }

      state.persistent.settings.overriddenPrompts2 ??= {} as OverriddenPrompts;
      state.persistent.settings.overriddenPrompts2[promptScopeType] ??= {};
      state.persistent.settings.overriddenPrompts2[promptScopeType][prompt.type] = {
        ...prompt,
        isEnabled,
      };
    },
    { userInteraction: 'click', eventName: 'toggle-is-prompt-enabled' },
  );
};

export const toggleIsCreatedPromptEnabled = async (promptScopeType: PromptScopeType, prompt: Prompt) => {
  await updateState(
    (state) => {
      const isEnabled = !prompt.isEnabled;
      state.persistent.settings.createdPrompts ??= {} as CreatedPrompts;
      state.persistent.settings.createdPrompts[promptScopeType] ??= [];
      const index = state.persistent.settings.createdPrompts[promptScopeType].findIndex(
        (p) => p.position === prompt.position,
      );
      state.persistent.settings.createdPrompts[promptScopeType][index] = { ...prompt, isEnabled };
    },
    { userInteraction: 'click', eventName: 'toggle-is-created-prompt-enabled' },
  );
};

export const deletePrompt = async (scope: PromptScopeType, prompt: Prompt) => {
  await updateState(
    (state) => {
      if (!state.persistent.settings.createdPrompts?.[scope]) {
        return;
      }

      state.persistent.settings.createdPrompts[scope] = state.persistent.settings.createdPrompts[
        scope
      ].filter((p) => p.position !== prompt.position);
    },
    { userInteraction: 'click', eventName: 'delete-prompt' },
  );
};

export const migratePromptsFormatV1 = async (defaultPrompts: Prompts) => {
  await updateState(
    (state) => {
      // already migrated
      if (state.persistent.settings.overriddenPromptsVersion === PromptsVersion.V2) {
        return;
      }

      // no overridden prompts to migrate
      if (!state.persistent.settings.overriddenPrompts) {
        delete state.persistent.settings.overriddenPrompts;
        state.persistent.settings.overriddenPromptsVersion = PromptsVersion.V2;
        state.persistent.settings.overriddenPrompts2 ??= {} as OverriddenPrompts;
        return;
      }
      const overriddenPrompts = state.persistent.settings.overriddenPrompts;

      const normalizedDefaultPrompts = migratePromptsFormat(defaultPrompts);
      const normalizedOverriddenPrompts = overriddenPrompts ? cloneDeep(overriddenPrompts) : undefined;
      if (normalizedOverriddenPrompts) {
        migrateOverriddenPromptsFormat(normalizedOverriddenPrompts);
      }

      // merge new defaults into overridden prompts
      if (normalizedOverriddenPrompts) {
        for (const [promptScopeKey, prompts] of Object.entries(normalizedOverriddenPrompts)) {
          if (normalizedOverriddenPrompts[promptScopeKey]) {
            for (const [promptKey, prompt] of Object.entries(prompts)) {
              const defaultPrompt = normalizedDefaultPrompts.data
                .find((scope) => scope.type === promptScopeKey)
                ?.prompts?.find((prompt) => prompt.type === promptKey);
              if (defaultPrompt) {
                normalizedOverriddenPrompts[promptScopeKey][promptKey] = {
                  ...defaultPrompt,
                  ...prompt,
                  isDefaultPrompt: false,
                };
              }
            }
          }
        }
      }

      state.persistent.settings.overriddenPrompts2 = normalizedOverriddenPrompts;
      delete state.persistent.settings.overriddenPrompts;
      state.persistent.settings.overriddenPromptsVersion = PromptsVersion.V2;
    },
    { userInteraction: 'click', eventName: 'migrate-prompts-format' },
  );
};

export const resetPrompt = async (scopeType: PromptScopeType, promptType: PromptType) => {
  await updateState(
    (state) => {
      state.persistent.settings.overriddenPrompts ??= {} as OverriddenPrompts;
      if (Object.hasOwn(state.persistent.settings.overriddenPrompts, scopeType)) {
        if (Object.hasOwn(state.persistent.settings.overriddenPrompts[scopeType], promptType)) {
          delete state.persistent.settings.overriddenPrompts[scopeType][promptType];
        }
      }

      state.persistent.settings.overriddenPrompts2 ??= {} as OverriddenPrompts;
      if (Object.hasOwn(state.persistent.settings.overriddenPrompts2, scopeType)) {
        if (Object.hasOwn(state.persistent.settings.overriddenPrompts2[scopeType], promptType)) {
          delete state.persistent.settings.overriddenPrompts2[scopeType][promptType];
        }
      }
    },
    { userInteraction: 'click', eventName: 'reset-prompt' },
  );
};

export const resetPrompts = async () => {
  await updateState(
    (state) => {
      state.persistent.settings.overriddenPrompts = undefined;
      state.persistent.settings.overriddenPrompts2 = undefined;
    },
    { userInteraction: 'click', eventName: 'reset-prompts' },
  );
};

export const toggleReadingProgressViewVariant = async (): Promise<void> => {
  await updateState(
    (state) => {
      const currentVariant = state.client.mobileReadingProgressViewVariant;
      const allVariants = Object.values(ReadingProgressViewVariant);
      const nextIndex = (allVariants.indexOf(currentVariant) + 1) % allVariants.length;
      state.client.mobileReadingProgressViewVariant = allVariants[nextIndex];
    },
    { userInteraction: 'tap', eventName: 'toggle-mobile-reading-progress-view-variant' },
  );
};
