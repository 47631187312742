import { isChunkContainer, isChunkedDocumentContentRoot } from '../../typeValidators';
import type { ChunkContainerElement } from '../types/chunkedDocuments';
import getClosestHTMLElement from './getClosestHTMLElement';

export function findChunkContainerForNode(node: Node, rootNode: Node): ChunkContainerElement | undefined {
  if (!isChunkedDocumentContentRoot(rootNode)) {
    return undefined;
  }
  return getClosestHTMLElement(node, isChunkContainer, rootNode);
}
