import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';
import pick from 'lodash/pick';

import { ReadwiseFetchServerError } from '../../utils/Errors';
import { globalState } from '../models';
import { DefaultModel, PromptModel, PromptScope1, PromptScopeType, PromptsVersion } from './constants';
import { CreatedPrompts, OverriddenPrompts, Prompts, Prompts1, Prompts2 } from './types';

export function canUseOpenAIModel(model: PromptModel) {
  const hasOpenAIApiKey = globalState((state) => Boolean(state.persistent.settings.openai?.apiKey));
  return model === DefaultModel || hasOpenAIApiKey;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function isPromptsFormat1(prompts: Prompts): prompts is Prompts1 {
  return Array.isArray(prompts);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function isPromptsFormat2(prompts: Prompts): prompts is Prompts2 {
  return Object.hasOwn(prompts, 'version') && (prompts as Prompts2).version === PromptsVersion.V2;
}

export function migratePromptsFormat(prompts: Prompts) {
  if (isPromptsFormat1(prompts)) {
    return {
      version: PromptsVersion.V2,
      data: [
        {
          type: PromptScopeType.Automatic,
          title: 'Automatic prompts',
          description: 'Applies to newly saved documents automatically',
          prompts: prompts.map((prompt) => ({
            type: prompt.type,
            title: prompt.title,
            description: prompt.description,
            model: prompt.model,
            system: prompt.system,
            template: prompt.template,
            isCustomizable: true,
            isEnabled: true,
          })),
        },
        {
          type: PromptScopeType.DocumentSpecial,
          title: 'Special document level prompts',
          description: 'Applies special metadata to the document',
          prompts: prompts.map((prompt) => ({
            type: prompt.type,
            title: prompt.title,
            description: prompt.description,
            model: prompt.model,
            system: prompt.system,
            template: prompt.template,
            isCustomizable: false,
            isEnabled: true,
          })),
        },
      ],
    } as Prompts2;
  }

  if (isPromptsFormat2(prompts)) {
    return cloneDeep(prompts);
  }

  throw new Error('Prompts are malformed or provided in an unsupported format.');
}

export function migrateOverriddenPromptsFormat(prompts: OverriddenPrompts) {
  for (const [overriddenPromptScope, overriddenScopePrompts] of Object.entries(prompts)) {
    // only pick the minimum number of properties, we can override most with defaults
    const filteredOverriddenScopePrompts = {};
    // eslint-disable-next-line guard-for-in
    for (const promptKey in overriddenScopePrompts) {
      filteredOverriddenScopePrompts[promptKey] = pick(
        overriddenScopePrompts[promptKey],
        'model',
        'template',
      );
    }

    if (overriddenPromptScope === PromptScopeType.Document) {
      prompts[PromptScopeType.Automatic] = filteredOverriddenScopePrompts;
      prompts[PromptScopeType.DocumentSpecial] = filteredOverriddenScopePrompts;
      delete prompts[PromptScope1.Document];
    }
  }

  return prompts;
}

/**
 * Normalize the default and user-customized prompts into a standard (latest)
 * format, and index them to make them accessible for application code.
 *
 * @param defaultPrompts
 * @param overriddenPrompts
 * @param createdPrompts
 */
export function transformPrompts(
  defaultPrompts: Prompts,
  overriddenPrompts?: OverriddenPrompts,
  createdPrompts?: CreatedPrompts,
) {
  const normalizedDefaultPrompts = migratePromptsFormat(defaultPrompts);
  const overriddenPromptsVersion = globalState(
    (state) => state.persistent.settings.overriddenPromptsVersion,
  );

  // if version is not set, we need to migrate the overridden prompts from v1 to v2
  const normalizedOverriddenPrompts = overriddenPrompts ? cloneDeep(overriddenPrompts) : undefined;
  if (normalizedOverriddenPrompts && overriddenPromptsVersion !== PromptsVersion.V2) {
    migrateOverriddenPromptsFormat(normalizedOverriddenPrompts);
  }

  // merge overridden prompts
  if (normalizedOverriddenPrompts) {
    for (const promptScope of normalizedDefaultPrompts.data) {
      if (normalizedOverriddenPrompts[promptScope.type]) {
        for (const prompt of promptScope.prompts) {
          if (normalizedOverriddenPrompts[promptScope.type][prompt.type]) {
            assign(prompt, normalizedOverriddenPrompts[promptScope.type][prompt.type]);
            prompt.isDefaultPrompt = false;
          }
        }
      }
    }
  }

  // merge created prompts
  if (createdPrompts) {
    for (const promptScope of normalizedDefaultPrompts.data) {
      if (createdPrompts?.[promptScope.type]) {
        promptScope.prompts.push(...createdPrompts[promptScope.type]);
      }
    }
  }

  return normalizedDefaultPrompts;
}

export function getModelLabel(model: PromptModel) {
  switch (model) {
    case PromptModel.GPT_35_TURBO:
      return 'GPT-3.5 Turbo';
    case PromptModel.GPT_4:
      return 'GPT-4';
    case PromptModel.GPT_4_TURBO:
      return 'GPT-4 Turbo';
    case PromptModel.GPT_4o:
      return 'GPT-4o';
    case PromptModel.GPT_4o_MINI:
      return 'GPT-4o mini';
    case PromptModel.O1:
      return 'o1';
    case PromptModel.O1_MINI:
      return 'o1-mini';
    case PromptModel.O3_MINI:
      return 'o3-mini';
    default:
      return 'GPT-4o mini';
  }
}

export function errorIncludesResponse(error: unknown): error is ReadwiseFetchServerError {
  return isObject(error) && Object.hasOwn(error, 'response');
}
