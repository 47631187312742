import '../networkDetector.platform';

import * as jsonpatch from 'readwise-fast-json-patch';

// eslint-disable-next-line import/no-cycle
import sendStateUpdateToApi from '../reader-extension/injection/sendStateUpdateToApi';
import type { UserEvent, UserEventWithDataUpdate } from '../types';
import {
  HandleStateUpdateSideEffectsParameter,
  HandleStateUpdateSideEffectsResult,
} from '../types/stateUpdates';
import { isExtension } from '../utils/environment';
// eslint-disable-next-line import/no-cycle
import { addUserEvent, recentUserEventsWithDataUpdates } from './models';
// eslint-disable-next-line import/no-cycle
import background, { portalGate as portalGateToBackground } from './portalGates/toBackground';
import { createUserEvent } from './userEvents';

export default async function handleStateUpdateSideEffects({
  addUserEventToZustandState = addUserEvent,
  queueStateUpdateFromForeground = isExtension
    ? sendStateUpdateToApi
    : background.queueStateUpdateFromForeground,
  ...options
}: HandleStateUpdateSideEffectsParameter): HandleStateUpdateSideEffectsResult {
  const jsonPatchOperations = options.jsonPatchOperations ?? { forward: [], reverse: [] };
  const didChangePersistentState = jsonPatchOperations.forward.length > 0;
  const didChangeAnyState = didChangePersistentState || options.didChangeAnyState; // E.g. transient state

  const shouldCreateUserEvent =
    options.shouldCreateUserEvent !== false && (Boolean(options.userInteraction) || didChangeAnyState);

  let userEvent: UserEvent | undefined;
  const eventName = options.eventName;

  // Return early (do absolutely nothing) if there are no changes
  if (!shouldCreateUserEvent && !didChangeAnyState) {
    return { userEvent };
  }
  if (shouldCreateUserEvent) {
    let dataUpdates: NonNullable<Parameters<typeof createUserEvent>[0]>['dataUpdates'];
    if (didChangePersistentState && !options.shouldNotSendPersistentChangesToServer) {
      dataUpdates = createDataUpdatesForEvent(jsonPatchOperations, options);
    }

    userEvent = createUserEvent({
      correlationId: options.correlationId,
      dataUpdates,
      eventName,
      userInteraction: options.userInteraction,
    });

    if (options.onSentToServer) {
      const onSentToServer = options.onSentToServer;
      const newUserEventId = userEvent.id;
      const onBackgroundToServerUpdatesConsumed = (userEventIds: UserEvent['id'][]) => {
        if (!userEventIds.includes(newUserEventId)) {
          return;
        }
        portalGateToBackground.off(
          'backgroundToServerUpdatesConsumed',
          onBackgroundToServerUpdatesConsumed,
        );
        onSentToServer();
      };
      portalGateToBackground.on(
        'backgroundToServerUpdatesConsumed',
        onBackgroundToServerUpdatesConsumed,
      );
    }

    addUserEventToZustandState(userEvent as UserEvent, { correlationId: options.correlationId });
  }

  // This exists if we want to send the changes to the server or if the change is undoable; this is where we do all that
  if (userEvent?.dataUpdates) {
    const eventWithDataUpdate = userEvent as UserEventWithDataUpdate;
    // Defaults to true
    if (options.isUndoable !== false) {
      recentUserEventsWithDataUpdates.push(eventWithDataUpdate);
    }
    await queueStateUpdateFromForeground(eventWithDataUpdate);
  }

  return { userEvent };
}

function createDataUpdatesForEvent(
  jsonPatchOperations: { forward: jsonpatch.Operation[]; reverse: jsonpatch.Operation[]; },
  options: HandleStateUpdateSideEffectsParameter,
): { forwardPatch: jsonpatch.Operation[]; reversePatch: jsonpatch.Operation[]; } {

  // For some special cases, set testValues in the forwardPatch to support non-last-write-wins behavior
  if (options.eventName === 'document-progress-position-updated') {
    const forwardPatch = Array.from(jsonPatchOperations.forward);

    // Find if there's a scrollDepth operation
    const scrollDepthOperation = forwardPatch.find(
      (operation) => operation.path.endsWith('/readingPosition/scrollDepth') && operation.op === 'replace',
    ) as jsonpatch.ReplaceOperation<number>;

    // If we found a scrollDepth operation, add a test operation at the beginning
    // This operation ensures that a client doing a document-progress-position-updated event will never _lower_ the
    //  reading progress of a document backwards to earlier in the book (a very common race condition).
    if (scrollDepthOperation && typeof scrollDepthOperation.value === 'number') {
      // The test operation checks that the server-side value is LESS THAN the client-side value
      // This means we only update if we're moving forward in the document, not backward
      const testOperation: jsonpatch.Operation = {
        op: 'test',
        path: scrollDepthOperation.path,
        value: `<${scrollDepthOperation.value}`,
      };

      // Add the test operation at the beginning of the array
      forwardPatch.unshift(testOperation);
    }

    return {
      forwardPatch,
      reversePatch: jsonPatchOperations.reverse,
    };
  }

  return {
    forwardPatch: jsonPatchOperations.forward,
    reversePatch: jsonPatchOperations.reverse,
  };
}
